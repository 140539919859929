import './App.css';
import Minter from './Minter'

import './fonts/SkaterDudes.woff';
import './fonts/SkaterDudes.woff2';
import './fonts/Outfit-Regular.woff';
import './fonts/Outfit-Regular.woff2';


function App() {
  return (
    <div className="App">
      {/* <div class="page-width banner-photo">
        <div class="promo-image-parent">
          <img src="overlay-title.png" loading="lazy" alt="" class="promo-image"></img>

          </div>
      </div> */}

      <div class="header-parent">
        <div class="page-width logo-container">
          <img src="Quirkies-black-Graff.png" loading="lazy" sizes="60px" alt="" class="image-3"></img>
          </div>
      </div>
      
      <div class="page-width main-content wf-section">
        <div class="redirect-section">
        <Minter></Minter>
        </div>
      </div>
      <div class="footer wf-section">
        <div class="footer-main-content">
          <p class="powered-by-text">Powered by Top Drawer Merch</p>
        </div>
         
      </div>
    </div>
  );
}

export default App;
